import { withLeadingSlash, withoutTrailingSlash } from 'ufo'
import { createEventHook, useLastChanged, type EventHookOn } from '@vueuse/core'
import { type Favorite as FavoriteCollectionType } from '../lib/favorites'

type Favorite = Omit<FavoriteCollectionType, 'id'>

const normalizePath = (path: string) =>
  withLeadingSlash(withoutTrailingSlash(path))

export function useFavorites() {
  const collectionAdd = createEventHook<Favorite[]>()
  const collectionRemove = createEventHook()

  if (import.meta.server) {
    return useFavoritesStub({
      onAdded: collectionAdd.on,
      onRemoved: collectionRemove.on,
    })
  }

  const { $favorites } = useNuxtApp()
  const { collection } = $favorites()

  const items = computed(() => collection.find().fetch())
  const count = computed(() => items.value.length)
  const lastChanged = useLastChanged(items, { deep: true })

  const addFavorite = (item: Omit<Favorite, 'added'>) => {
    const exists = collection.find({ uuid: item.uuid }).count() > 0
    if (exists) return

    const row = {
      uuid: item.uuid,
      title: item.title,
      date: item.date,
      path: normalizePath(item.path),
      added: new Date(),
    }

    console.debug('[useFavorites] adding', row)
    collection.insert(row)
    console.debug('[useFavorites] added', row)
    collectionAdd.trigger([row])
  }

  const removeFavorite = (uuid: string) => {
    if (!hasFavorite(uuid)) return

    console.debug('[useFavorites] removing', uuid)
    if (collection.removeOne({ uuid })) {
      console.debug('[useFavorites] removed', uuid)
      collectionRemove.trigger()
    }
  }

  const hasFavorite = (uuid: string) => {
    return collection.find({ uuid }).count() > 0
  }

  return {
    state: items,
    items,
    count,
    lastChanged,
    addFavorite,
    removeFavorite,
    hasFavorite,
    onAdded: collectionAdd.on,
    onRemoved: collectionRemove.on,
  }
}

type UseFavoritesReturn = {
  onAdded: EventHookOn
  onRemoved: EventHookOn
}

function useFavoritesStub(defaults: UseFavoritesReturn) {
  const items = ref<Favorite[]>([])
  const count = ref(0)
  const lastChanged = ref(new Date())

  const addFavorite = (item: Omit<Favorite, 'added'>) => {
    const exists = items.value.find((i) => i.uuid === item.uuid)
    if (exists) return

    const row = {
      uuid: item.uuid,
      title: item.title,
      date: item.date,
      path: normalizePath(item.path),
      added: new Date(),
    }

    items.value.push(row)
  }

  const removeFavorite = (uuid: string) => {
    const exists = items.value.find((i) => i.uuid === uuid)
    if (!exists) return

    items.value = items.value.filter((i) => i.uuid !== uuid)
  }

  const hasFavorite = (uuid: string) => {
    return items.value.findIndex((i) => i.uuid === uuid) > -1
  }

  return {
    ...defaults,
    state: items,
    items,
    count,
    lastChanged,
    addFavorite,
    removeFavorite,
    hasFavorite,
  }
}
